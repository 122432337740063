
import { Component, Prop, Vue } from "vue-property-decorator";
import { getBaseImagesUrl } from "../helpers/settingsHelper";
import { AssistantInfo } from "./assistantsWebServices";
import AssistantGallery from "@/assistants/AssistantGallery.vue";

@Component({
    components: {
        AssistantGallery
    }
})
export default class AssistantAvatar extends Vue {
    @Prop() userId?: string;
    @Prop() assistant!: AssistantInfo | undefined;
    @Prop( { default: false } ) galleryEnabled!: boolean
    @Prop({ default: null, required: false }) photoId!: string;

    get imagesUrl() {
        return getBaseImagesUrl();
    }

    get avatarId() {
        if (this.photoId) {
          return this.photoId
        }

        return this.assistant?.photos?.find((p) => true)?.id;
    }

    showGallery(): void {
        if (!this.galleryEnabled) {
          return
        }

        (this.$refs['modal'] as any).show();
    }
}
