
import { Component, Prop, Vue } from "vue-property-decorator";
import { getBaseImagesUrl } from "../helpers/settingsHelper";
import { UserPhoto, getPhotos } from "@/serviceClients/userWebServices";
import { pushError } from "@/core";
import { Carousel, Slide } from "vue-carousel";

@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class AssitantGallery extends Vue {
    @Prop({ default: null, required: true }) userId!: string
    isLoading = true;
    photos: UserPhoto[] = [];
    currentPhotoId: string = "";

    get imagesUrl() {
        return getBaseImagesUrl();
    }

    setActivePhoto(): void {
        if (this.photos.length > 0) {
            this.currentPhotoId = this.photos[0].id;
        }
    }

    async mounted(): Promise<any> {
        try {
            const { data } = await getPhotos(this.userId)

            this.photos = data;

            this.setActivePhoto()
        } catch (error) {
            pushError(error.response?.data?.error || error, { title: this.$t("An error occurred while fetching data") });
        } finally {
            this.isLoading = false;
        }
    }
}
