
import { Component, Prop, Vue } from "vue-property-decorator";
import { CategoryGroup, ServiceType } from "../serviceClients/categoryWebServices";

@Component
export default class AssistantCategories extends Vue {
    @Prop() categories!: CategoryGroup[];
    allOnlineCategoriesShown = false
    allOfflineCategoriesShown = false

    get onlineCategories() {
        return (this.categories || [])
            .filter(c => c.serviceType == ServiceType.Online);
    }

    get offlineCategories() {
        return (this.categories || [])
            .filter(c => c.serviceType == ServiceType.Offline);
    }

    onlineCategoryShown(index: number) {
        return this.allOnlineCategoriesShown || index < 3
    }

    onClickSeeMoreOnlineCategories() {
        this.allOnlineCategoriesShown = true
    }

    offlineCategoryShown(index: number) {
        return this.allOfflineCategoriesShown || index < 3
    }

    onClickSeeMoreOfflineCategories() {
        this.allOfflineCategoriesShown = true
    }
}
