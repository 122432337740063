
import { Component, Prop } from "vue-property-decorator";
import {
  AssistantInfo,
  getAssistantInformation,
} from "../assistants/assistantsWebServices";
import store from "../store/index";
import { AccountType } from "../register/registerModel";
import RatingStars from "./RatingStars.vue";
import { DetailsBase } from "./DetailsBase";
import {
  EducationLevel,
  Gender,
  LanguageLevel,
} from "../accountSetup/accountSetupService";
import AssistantCategories from "./AssistantCategories.vue";
import AssistantAvatar from "./AssistantAvatar.vue";
import { getLevelOptionTranslation } from "@/accountSetup/languageLevelHelper";
import { getLanguageName } from "@/core/translate";
import { pushError } from "@/core";
import MarketingBadge from "@/components/MarketingBadge.vue"
import { displayHeight } from "@/accountSetup/assistantHeightHelper";
import { getMaritalStatusOptionTranslation } from "@/accountSetup/maritalStatusHelper";
import Constants from "@/Constants";

@Component({
  components: {
    RatingStars,
    AssistantCategories,
    AssistantAvatar,
    MarketingBadge,
  },
})
export default class AssitantDetails extends DetailsBase {
  @Prop() userId!: string;
  @Prop() justView!: boolean;
  get isSpecial () { return Constants.IsSupportUserId(this.userId); }

  localAsssistant: AssistantInfo | undefined = {} as AssistantInfo;
  isLoading = false;
  isMessageLoading = false;
  message = "";

  get canMessage() {
    return this.isSpecial || store.getters.module.user?.accountType !== AccountType.Assistant;
  }

  get assistant() {
    return this.localAsssistant;
  }

  languageName(code: string) {
    return getLanguageName(code);
  }

  languageLevel(level: LanguageLevel) {
    return getLevelOptionTranslation(this, level);
  }

  get hasCity() {
    return !!this.assistant?.address && !!this.assistant?.address?.city;
  }

  get educationLevel() {
    switch (this.assistant?.educationLevel) {
      case EducationLevel.Higher:
        return this.$t("Higher");
      case EducationLevel.Student:
        return this.$t("Student");
      case EducationLevel.Student:
        return this.$t("Other");
      default:
        return undefined;
    }
  }

  get gender() {
    if (this.assistant?.accountType === AccountType.Client) {
      return undefined;
    }

    switch(this.assistant?.gender) {
      case Gender.Male:
        return this.$t("Male")
      case Gender.Female:
        return this.$t("Female")
      default:
        return this.$t("No info about gender")
    }
  }


  get maritalStatus() {
    if (this.assistant?.accountType === AccountType.Client) {
      return undefined;
    }

    if(!!this.assistant?.maritalStatus){
      return getMaritalStatusOptionTranslation(this, this.assistant?.maritalStatus)
    }

    return undefined;
  }

  get height() {
    return displayHeight(this.assistant?.height)
  }

  get assistantName() {
    return `${this.assistant?.firstName} ${this.assistant?.lastName?.slice(0,1)}.`
  }

  mounted() {
    this.isLoading = true;
    this.loadAssistantInfo().finally(() => {
      this.isLoading = false;
    })
    .catch((error) => {
      pushError(error.response?.data?.error || error, { title: this.$t("An error occurred while fetching data") });
    });
    if (this.$route.name === "AssistantMessage") {
      this.onSendMessageClicked();
    }
  }

  onRated() {
    this.loadAssistantInfo().catch(() => { /* it's OK, still have the previously loaded info */ });
  }

  private loadAssistantInfo() {
    return getAssistantInformation(this.userId)
      .then((response) => {
        this.localAsssistant = response.data;
        store.commit.module.setActiveModuleName(`${this.assistant?.firstName} ${this.assistant?.lastName}`);
      })
  }

  onSendMessageClicked() {
    if (!store.getters.module.user) {
      pushError(this.$t("You have to be logged in to send a message").toString());
      store.commit.module.setUrlToRedirectAfterLogon(
        `/assistant/${this.userId}`
      );
      this.$router.push("/login").catch(() => { /* noop */ });
    } else if (
      store.getters.module.balance &&
      store.getters.module.balance <= 0 &&
      !this.isSpecial
    ) {
      pushError(this.$t("Please add funds to your wallet before starting a conversation").toString());
      this.$router.push("/transactions").catch(() => { /* noop */ });
      return;
    } else {
      this.$router.push(`/conversations/${this.userId}`).catch(() => { /* noop */ });
    }
  }

  get assistantSearchRedirectUrl() {
    return store.getters.module.assistantSearchUrlToRedirect
  }
}
