import { MaritalStatus } from "./accountSetupService"

export const getMaritalStatusOptionsTranslations = (vue: Vue) => {
    return [
        { value: MaritalStatus.Single, text: vue.$t("Single") },
        { value: MaritalStatus.Relationship, text: vue.$t("Relationship") },
        { value: MaritalStatus.Engaged, text: vue.$t("Engaged") },
        { value: MaritalStatus.Married, text: vue.$t("Married") },
        { value: MaritalStatus.OpenRelationship, text: vue.$t("Open relationship") },
        { value: MaritalStatus.Complicated, text: vue.$t("Complicated") },
        { value: MaritalStatus.Separated, text: vue.$t("Separated") },
        { value: MaritalStatus.Divorced, text: vue.$t("Divorced") },
        { value: MaritalStatus.NotAvailable, text: vue.$t("I'd rather not say") },
    ]
}

export const getMaritalStatusOptionTranslation = (vue: Vue, value: MaritalStatus) => {
    return getMaritalStatusOptionsTranslations(vue).filter(o => o.value === value)[0].text;
}