import Vue from 'vue';
import { AssistantInfo } from './assistantsWebServices';

export abstract class DetailsBase extends Vue {

    abstract get assistant(): AssistantInfo | undefined;

    get age() {
        if(!this.assistant!.birthDate) {
            return undefined;
        }
        return (new Date().getFullYear() - new Date(Date.parse(this.assistant!.birthDate!)).getFullYear());
    }
}