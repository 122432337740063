
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MarketingBadge extends Vue {
  @Prop() referralsNumber!: number;

  Levels: {
    level: number;
    referrals: number;
  }[] = [
    { level: 1, referrals: 30 },
    { level: 2, referrals: 100 },
    { level: 3, referrals: 250 },
    { level: 4, referrals: 500 },
    { level: 5, referrals: 1000 },
  ];

  get display() {
    return !!this.referralsNumber && this.level > 0;
  }

  get level(): number {
    for (let i = 0; i < this.Levels.length; i++) {
        if (this.referralsNumber < this.Levels[i].referrals) {
            return i;
        }
    }

    return this.Levels[this.Levels.length - 1].level;
  }
}
