import { LanguageLevel } from './accountSetupService'

export const getLevelOptionsTranslations = (vue: Vue) => {
    return [
        { value: LanguageLevel.Basic, text: vue.$t("Basic") },
        { value: LanguageLevel.Intermediate, text: vue.$t("Intermediate") },
        { value: LanguageLevel.Advanced, text: vue.$t("Advanced") },
        { value: LanguageLevel.Native, text: vue.$t("Native") }
    ]
}

export const getLevelOptionTranslation = (vue: Vue, value: LanguageLevel) => {
    return getLevelOptionsTranslations(vue).filter(o => o.value === value)[0].text;
}